<template>
  <div>
    <Row class="text-center" v-if="isAuth('Contract_Edit')">
      <Divider dashed><span class="divider-text">创建结算单</span></Divider>
      <Button
          type="success"
          class="m-l-8"
          size="small"  @click="handleCreateAgreement">开始创建结算单</Button>
    </Row>

    <Divider dashed><span class="divider-text">结算单基础信息</span></Divider>
    <Row class="p-b-10">
      <i-col span="6">结算单编号：</i-col>
      <i-col span="18">
        {{ contract.code }}
        <a @click="handleChangeCode">更改编号</a>
      </i-col>
    </Row>
    <Row class="p-b-10">
      <i-col span="6">结算单名称</i-col>
      <i-col span="18">
        {{ contract.contractName }}
      </i-col>
    </Row>
    <Row>
      <i-col span="24" v-if="isAuth('Contract_FileSetting')">
        <Divider dashed><span class="divider-text">结算单文件标记</span></Divider>
        <Row class="p-t-2">
          <i-col span="6">当前标记：</i-col>
          <i-col span="18">
            {{ contract.sealStatusName }}
          </i-col>
          <i-col span="6">新的标记：</i-col>
          <i-col span="18">
            <Radio-group size="small" v-model="sealStatus">
              <Radio
                class="m-b-5"
                v-for="(item, index) in sealStatusEnum"
                :key="index"
                :label="item.value"
                border
                >{{ item.label }}</Radio
              >
            </Radio-group>
          </i-col>
        </Row>
        <Row class="p-t-20">
          <i-col class="text-right p-r-10">
            <Button
              type="success"
              size="small"
              :disabled="sealStatus === contract.sealStatus"
              @click="saveSealStatus"
              >保存标记</Button
            >
          </i-col>
        </Row>
      </i-col>
      <i-col span="24">
        <Divider dashed v-show="files.length"
          ><span class="divider-text">已上传文件列表</span></Divider
        >
        <Row v-show="files.length">
          <i-col span="24">
            <Row v-show="files.length" class="table-title p-t-2">
              <i-col span="12" class="p-l-5 p-t-5 p-b-5">文件名称</i-col>
              <i-col span="6" class="p-l-5 p-t-5 p-b-5">文件大小</i-col>
              <i-col span="6" class="text-right p-r-5 p-t-5 p-b-5">操作</i-col>
            </Row>
            <Row
              v-for="(file, fileIndex) in files"
              :key="fileIndex"
              :class="fileIndex % 2 === 0 ? 'table-row-1' : 'table-row-2'"
              v-show="files.length"
            >
              <i-col span="12" class="p-l-5 p-t-5 p-b-5">{{
                file.fileName
              }}</i-col>
              <i-col span="6" class="p-l-5 p-t-5 p-b-5"
                >{{ file.length }}KB</i-col
              >
              <i-col span="6" class="text-right p-l-5 p-t-5 p-b-5">
                <a
                  href="javascript:;"
                  class="p-r-5"
                  @click="handleDownload(file)"
                  >下载</a
                >
                <a
                  class="delete"
                  v-if="isAuth('Contract_fileDelete')"
                  @click="deleteFile(file)"
                  >删除</a
                >
              </i-col>
            </Row>
          </i-col>
        </Row>
      </i-col>
      <!-- <i-col span="24" > -->
      <i-col span="24" v-if="isAuth('Contract_fileUpload')">
        <Divider dashed><span class="divider-text">新文件上传</span></Divider>
        <Row>
          <i-col span="24">
            <Upload
              multiple
              type="drag"
              :before-upload="handleBeforeUploadFile"
              action=""
            >
              <div style="padding: 20px 0">
                <Icon
                  type="ios-cloud-upload"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <p>点击或拖拽文件到这里进行上传</p>
              </div>
            </Upload>
          </i-col>
          <i-col span="24">
            <Row v-show="fileList.length" class="table-title p-t-2">
              <i-col span="12" class="p-l-5 p-t-5 p-b-5">文件名称</i-col>
              <i-col span="6" class="p-l-5 p-t-5 p-b-5">文件大小</i-col>
              <i-col span="6" class="text-right p-r-5 p-t-5 p-b-5">操作</i-col>
            </Row>
            <Row
              v-for="(file, fileIndex) in fileList"
              :key="fileIndex"
              v-show="fileList.length"
              :class="fileIndex % 2 === 0 ? 'table-row-1' : 'table-row-2'"
            >
              <i-col span="12" class="p-l-5 p-t-5 p-b-5">{{ file.name }}</i-col>
              <i-col span="6" class="p-l-5 p-t-5 p-b-5"
                >{{ (file.size / 1024).toFixed(2) }}KB</i-col
              >
              <i-col span="6" class="text-right p-l-5 p-t-5 p-b-5"
                ><a class="delete" @click="handleRemove(file)">删除</a></i-col
              >
            </Row>
          </i-col>
          <i-col class="text-right p-r-10 p-t-20" span="24">
            <Button type="success" size="small" @click="handleUpload()"
              >立即上传</Button
            >
          </i-col>
        </Row>
      </i-col>
    </Row>
    <Modal
      v-model="beginUpload"
      width="400"
      :styles="{ top: '250px' }"
      :mask-closable="false"
      :closable="false"
      footer-hide
    >
      <h3 class="text-center">上传文件中..</h3>
      <Progress
        :percent="percentage"
        :stroke-width="20"
        status="active"
        text-inside
      ></Progress>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { getFileList, deleteFiles } from '@/api/scp/contractfile'
import { downloadFileRequest } from '@/utils/download'
import {
  sealContractSealStatus,
  checkContractDetail,
  changeContractCode
} from '@/api/scp/contract'

export default {
  components: {},
  data () {
    return {
      type: 'STATEMENT_MANAGE_ATTACHMENT_FILE',
      beginUpload: false,
      percentage: 0, // 文件上传进度
      sealStatusEnum: [
        { label: '未出', value: 0 },
        { label: '已出', value: 1 },
        { label: '已归档', value: 2 }
      ],
      contract: {},
      sealStatus: 0,
      // 已上传
      files: [],
      // 新上传
      fileList: []
    }
  },
  created () {
    if (this.contractId) {
      this.loadContractInfo()
    }
  },
  methods: {
    handleChangeCode () {
      let newCode = this.contract.code
      this.$Modal.confirm({
        title: '更改结算单的编号',
        render: (h) => {
          return h('Input', {
            props: {
              value: newCode
            },
            on: {
              'on-change': (event) => {
                newCode = event.target.value
              }
            }
          })
        },
        onOk: () => {
          changeContractCode({
            contractId: this.contractId,
            contractCode: newCode
          }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '更改编号成功' })

              this.$store.commit('set_contract_update', new Date())
              this.loadContractInfo()
            }
          })
        }
      })
    },
    handleCreateAgreement () {
      // this.$emit('on-create')
      this.$store.commit('set_contract_contractId', 0)
      this.$store.commit('set_contract_statementSetting', false)
    },
    loadContractInfo () {
      const _this = this
      checkContractDetail({ contractId: _this.contractId }).then((res) => {
        _this.contract = res
        _this.sealStatus = res.sealStatus
        _this.loadFileList()
      })
    },
    handleBeforeUploadFile (file) {
      this.fileList.push(file)
      return false
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    },
    // 保存文件标记
    saveSealStatus () {
      const _this = this
      if (_this.sealStatus !== _this.contract.sealStatus) {
        var dataquery = {
          contractId: _this.contractId,
          sealStatus: _this.sealStatus
        }
        sealContractSealStatus(dataquery).then((res) => {
          if (res && !res.errcode) {
            _this.loadContractInfo()

            _this.$store.commit('set_contract_update', new Date())
            _this.$Notice.success({ desc: '保存文件标记成功' })
          }
        })
      }
    },
    loadFileList () {
      getFileList({ relateId: this.contract.versionId, type: this.type }).then(
        (res) => {
          this.files = res
        }
      )
    },
    handleDownload (file) {
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
          '/ooh-scp/v1/contractfile/downloadfile',
        { fileId: file.id },
        file.fileName
      )
    },
    deleteFile (file) {
      this.$Modal.confirm({
        title: '删除文件',
        content: '请确认要删除文件[' + file.fileName + ']？',
        onOk: () => {
          const fileIds = []
          fileIds.push(file.id)
          deleteFiles({ fileIds: JSON.stringify(fileIds) }).then((res) => {
            if (res && !res.errcode) {
              this.loadFileList()
            } else {
              this.$Notice.error({ desc: res.errmsg })
            }
          })
        }
      })
    },
    // 確認上傳文件
    handleUpload () {
      this.beginUpload = true
      this.percentage = 0
      // 开始批量执行上传操作
      const that = this
      const uploadData = new FormData()
      uploadData.append('relateId', this.contract.versionId)
      uploadData.append('type', this.type)

      this.fileList.forEach(function (file, index) {
        uploadData.append('files', file)
      })

      axios
        .post(
          process.env.VUE_APP_API_URL_V2 +
            '/ooh-scp/v1/contractfile/uploadfiles',
          uploadData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'ooh-auth': getStorage('ooh-token')
            },
            withCredentials: true,
            onUploadProgress (progress) {
              // 处理上传文件进度条数据
              that.percentage = Math.round(
                (progress.loaded / progress.total) * 100
              )
            }
          }
        )
        .then((res) => {
          this.beginUpload = false
          if (res && res.data && !res.data.errcode) {
            this.loadFileList()
            this.fileList = []
            this.$Notice.success({ desc: '文件上传成功' })
          } else {
            this.$Notice.error({ desc: res.data.errmsg })
          }
        })
    }
  },
  computed: {
    contractId () {
      return this.$store.state.contract
        ? this.$store.state.contract.contractId
        : 0
    }
  },
  watch: {
    contractId (val) {
      if (val) {
        this.loadContractInfo()
      }
    }
  }
}
</script>
